import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { primaryAPI } from '../../utilities/apiEndpointUtility';

import {
	emailConfirmation,
	emailConfirmationCompleted,
	emailConfirmationFailed
} from './emailConfirmationSlice';
import { handleAxiosError } from '../../utilities/errorUtility';

let programStopCancellationEmailRequest = {};

const emailConfirmationEpic = (action$) =>
	action$.pipe(
		ofType(emailConfirmation),
		mergeMap(async (action) => {
			try {
				programStopCancellationEmailRequest = action.payload;
				const response = await axios.post(
					`${primaryAPI()}/membership-program-stop/cancellation-email-confirmation`,

					programStopCancellationEmailRequest
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [emailConfirmationCompleted(res)]),
		catchError((error, source) =>
			merge(of(emailConfirmationFailed(error.message)), source)
		)
	);

export default [emailConfirmationEpic];
