import { Typography } from '@mui/material';
import React from 'react';

const NoDataMatch = () => {
	return (
		<>
			<Typography variant='h4' component='h2'>
				We cannot locate your membership record at this time.
			</Typography>
			<Typography>
				Please call our call center at <strong>1-800-475-1942</strong> between
				the hours of 7 a.m. - 10 p.m., Monday - Friday (CT).
			</Typography>
		</>
	);
};

export default NoDataMatch;
