import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	requestRefund,
	requestRefundReset
} from '../../containers/CancelMembership/cancelMembershipSlice';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
	margin: '10px 0',
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const RefundRequest = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data: cancelFormResponse } = useSelector(
		(state) => state.cancelMembershipSlice.cancelMembership
	);

	const { data: verifyRecentlyMovedData } = useSelector(
		(state) => state.cancelMembershipSlice.verifyRecentlyMoved
	);

	const { result: requestCheck } = useSelector(
		(state) => state.cancelMembershipSlice.requestRefund
	);
	React.useEffect(() => {
		dispatch(requestRefundReset());
	}, []);
	React.useEffect(() => {
		if (
			!cancelFormResponse?.cancelRecordId &&
			!verifyRecentlyMovedData?.cancelRecordId
		) {
			navigate('/');
		}
	}, []);

	React.useEffect(() => {
		if (requestCheck) {
			navigate('/refund/confirmation');
		}
	}, [requestCheck]);

	const handleSubmit = () => {
		if (cancelFormResponse?.cancelRecordId) {
			dispatch(
				requestRefund({ cancelRecordId: cancelFormResponse?.cancelRecordId })
			);
		} else if (verifyRecentlyMovedData?.cancelRecordId) {
			dispatch(
				requestRefund({
					cancelRecordId: verifyRecentlyMovedData?.cancelRecordId
				})
			);
		} else {
			return undefined;
		}
	};

	return (
		<>
			<Typography fontWeight='bold'>
				As already indicated, your cancellation request was received and will be
				processed within one business day. There will be no further charges to
				your card.
			</Typography>
			<Typography>
				If you believe you are entitled to an <strong>additional refund</strong>{' '}
				for some reason, please click the request refund button below and a{' '}
				<strong>Refund Request Form</strong> will be mailed to you within 7-10
				business days.
			</Typography>
			<StyledButton
				variant='contained'
				color='success'
				size='small'
				onClick={handleSubmit}
			>
				Request Refund
			</StyledButton>
		</>
	);
};

export default RefundRequest;
