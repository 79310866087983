import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { primaryAPI } from '../../utilities/apiEndpointUtility';

import {
	cancelMembership,
	cancelMembershipCompleted,
	cancelMembershipFailed,
	verifyRecentlyMoved,
	verifyRecentlyMovedCompleted,
	verifyRecentlyMovedFailed,
	queueDataNotFound,
	queueDataNotFoundCompleted,
	queueDataNotFoundFailed,
	requestRefund,
	requestRefundCompleted,
	requestRefundFailed
} from './cancelMembershipSlice';
import { handleAxiosError } from '../../utilities/errorUtility';

const cancelMembershipEpic = (action$) =>
	action$.pipe(
		ofType(cancelMembership),
		mergeMap(async (action) => {
			try {
				const response = await axios.post(
					`${primaryAPI()}/membership-program-stop/subscriber/cancel-membership`,
					action.payload
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [cancelMembershipCompleted(res)]),
		catchError((error, source) =>
			merge(of(cancelMembershipFailed(error.message)), source)
		)
	);

const verifyRecentlyMovedEpic = (action$) =>
	action$.pipe(
		ofType(verifyRecentlyMoved),
		mergeMap(async (action) => {
			try {
				const response = await axios.post(
					`${primaryAPI()}/membership-program-stop/cancel-membership/recently-moved`,
					action.payload
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [verifyRecentlyMovedCompleted(res)]),
		catchError((error, source) =>
			merge(of(verifyRecentlyMovedFailed(error.message)), source)
		)
	);

const queueDataNotFoundEpic = (action$) =>
	action$.pipe(
		ofType(queueDataNotFound),
		mergeMap(async (action) => {
			try {
				const response = await axios.post(
					`${primaryAPI()}/membership-program-stop/cancel-membership/not-moved`,
					action.payload
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [queueDataNotFoundCompleted(res)]),
		catchError((error, source) =>
			merge(of(queueDataNotFoundFailed(error.message)), source)
		)
	);

const requestRefundEpic = (action$) =>
	action$.pipe(
		ofType(requestRefund),
		mergeMap(async (action) => {
			try {
				const response = await axios.post(
					`${primaryAPI()}/membership-program-stop/additional-refund`,
					action.payload
				);
				return response.data;
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [requestRefundCompleted(res)]),
		catchError((error, source) =>
			merge(of(requestRefundFailed(error.message)), source)
		)
	);

export default [
	cancelMembershipEpic,
	verifyRecentlyMovedEpic,
	queueDataNotFoundEpic,
	requestRefundEpic
];
