import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { catchError } from 'rxjs/operators';

// SLICES
import cancelMembershipSlice from '../containers/CancelMembership/cancelMembershipSlice';
import doNotSellSlice from '../containers/DoNotSell/doNotSellSlice';
import captchaValidSlice from '../containers/CaptchaValid/captchaValidSlice';
import emailConfirmationSlice from '../containers/EmailConfirmation/emailConfirmationSlice';

// EPICS
import cancelMembershipEpic from '../containers/CancelMembership/cancelMembershipEpics';
import doNotSellEpic from '../containers/DoNotSell/doNotSellEpics';
import captchaValidEpics from '../containers/CaptchaValid/captchaValidEpics';
import emailConfirmationEpic from '../containers/EmailConfirmation/emailConfirmationEpic';

const epicMiddleware = createEpicMiddleware();

const epics = combineEpics(
	...cancelMembershipEpic,
	...doNotSellEpic,
	...captchaValidEpics,
	...emailConfirmationEpic
);

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error(error);
			return source;
		})
	);

export const store = configureStore({
	reducer: {
		cancelMembershipSlice,
		doNotSellSlice,
		captchaValidSlice,
		emailConfirmationSlice
	},
	middleware: [epicMiddleware]
});

epicMiddleware.run(rootEpic);
