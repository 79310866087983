import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TextField, styled } from '@mui/material';
import {
	emailConfirmation,
	emailConfirmationReset
} from '../../containers/EmailConfirmation/emailConfirmationSlice';

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const Overview = ({ t }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [emailAddress, setEmailAddress] = React.useState('');
	const [confirmEmailAddress, setConfirmEmailAddress] = React.useState('');
	const [errorMessage, setErrorMessage] = React.useState('');

	const {
		data: cancelFormResponse,
		hasCompleted: hasCancelFormCompleted
	} = useSelector((state) => state.cancelMembershipSlice.cancelMembership);

	const { result } = useSelector(
		(state) => state.emailConfirmationSlice.emailConfirmation
	);

	const { data: verifyRecentlyMovedData } = useSelector(
		(state) => state.cancelMembershipSlice.verifyRecentlyMoved
	);
	React.useEffect(() => {
		if (!hasCancelFormCompleted) {
			navigate('/');
		}
	}, [hasCancelFormCompleted, cancelFormResponse]);

	const handlePopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		dispatch(emailConfirmationReset());
		setErrorMessage('');
	};

	const handleConfirmEmail = () => {
		if (emailAddress === confirmEmailAddress) {
			dispatch(
				emailConfirmation({
					emailAddress,
					cancelRecordId:
						cancelFormResponse?.cancelRecordId ||
						verifyRecentlyMovedData?.cancelRecordId
				})
			);
		} else {
			setErrorMessage('Not a valid email address or emails do not match.');
		}
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		hasCancelFormCompleted &&
		cancelFormResponse && (
			<React.Fragment>
				<Typography
					component='h2'
					sx={{ fontSize: '2em', fontWeight: 'bold', marginBottom: '10px' }}
				>
					{t('home:welcomeHeader')}
				</Typography>
				<Typography
					component='p'
					sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
				>
					Your confirmation number is:{' '}
					{cancelFormResponse?.cancellationNumber ||
						verifyRecentlyMovedData?.cancellationNumber}
				</Typography>
				<StyledBox component='section'>
					<Box>
						<Stack
							justifyContent='space-between'
							alignItems='center'
							direction='row'
						>
							<Stack>
								<Typography component='h3' variant='h5' fontWeight='bold'>
									Cancellation Confirmation
								</Typography>
							</Stack>
							<Stack>
								<Typography fontSize='0.8rem'>
									<Link onClick={() => print()}>Print for your records</Link>
								</Typography>
								<Typography fontSize='0.8rem'>
									<Link style={{ marginRight: '3px' }} onClick={handlePopOver}>
										Click here
									</Link>
									for an email confirmation of your cancellation.
								</Typography>
							</Stack>
						</Stack>

						<Typography fontWeight='bold' my={2}>
							Your cancellation request has been received and will be processed
							within one business day. There will be no further charges to your
							card.
						</Typography>
						<List>
							<ListItem>
								If you cancelled your membership during your trial period, you
								will not be charged the membership fee.
							</ListItem>
							<ListItem>
								If you cancelled after your trial period, no further billings
								will be charged to your account. If you joined one of our
								monthly programs, you will receive a refund of the most recent
								monthly billing posted to your credit card.
							</ListItem>
							<ListItem>
								<Typography>
									If you submit an email address by clicking on the link in the
									upper right-hand corner of this page, you will receive an
									email confirmation of your cancellation. Please add
									<a
										style={{ margin: '0 5px' }}
										href='mailto:membership@programstop.com'
									>
										membership@programstop.com
									</a>
									to your safe sender&apos;s list so that your cancellation
									confirmation email is not discarded or identified as spam
								</Typography>
							</ListItem>
							<ListItem>
								If you believe you are entitled to an additional refund for some
								reason, please&nbsp;
								<Link to='/refund'>click here</Link>.
							</ListItem>
						</List>
						<Typography>
							Please see the FAQs section if you have additional questions.
						</Typography>
						<Typography>
							We hope you will consider our membership programs in the future
							when the timing is better for you to enjoy our many exciting
							benefits and services.
						</Typography>
					</Box>
				</StyledBox>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
				>
					<Box sx={{ padding: '15px', fontWeight: 'bold' }}>
						{result && (
							<Typography color='green'>Email Sent Successfully</Typography>
						)}
						<Typography sx={{ my: 2 }}>
							If you would like to receive an email confirmation, please fill
							out your email address.
						</Typography>
						{errorMessage && (
							<Typography my={1} fontSize='0.8em' color='error'>
								{errorMessage}
							</Typography>
						)}
						<TextField
							size='small'
							fullWidth
							name='emailAddress'
							label='Email'
							onChange={(event) => setEmailAddress(event.target.value)}
						/>
						<TextField
							sx={{ my: 2 }}
							size='small'
							fullWidth
							name='confirmEmailAddress'
							label='Confirm Email'
							onChange={(event) => setConfirmEmailAddress(event.target.value)}
						/>
						<Button variant='contained' onClick={handleConfirmEmail}>
							Submit
						</Button>
					</Box>
				</Popover>
			</React.Fragment>
		)
	);
};

Overview.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(Overview);
