import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
} from 'react-router-dom';

// PAGES
import Home from '../../pages/Home';
import DoNotSell from '../../pages/DoNotSell';
import Faq from '../../pages/Faq';
import Layout from '../Layout';
import PageNotFound from '../../pages/404';
import Confirmation from '../../pages/Confirmation';
import VerifyZip from '../../pages/Confirmation/verifyZip';
import NoDataMatch from '../../pages/Confirmation/noMatch';
import RefundRequest from '../../pages/RefundRequest';
import RefundRequestConfirmation from '../../pages/RefundRequest/refundConfirmation';

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route path='' element={<Home />} />
					<Route path='donotsell' element={<DoNotSell />} />
					<Route path='confirmation' element={<Confirmation />} />
					<Route path='verify-zip' element={<VerifyZip />} />
					<Route path='refund' element={<RefundRequest />} />
					<Route
						path='refund/confirmation'
						element={<RefundRequestConfirmation />}
					/>
					<Route path='no-match' element={<NoDataMatch />} />
					<Route path='faq' element={<Faq />} />
					<Route path='home' element={<Navigate to='/' />} />
					<Route path='*' element={<PageNotFound />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default AppRouter;
