import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import TextInput from '../../components/TextInput';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, styled } from '@mui/material';
import {
	queueDataNotFound,
	verifyRecentlyMoved
} from '../../containers/CancelMembership/cancelMembershipSlice';

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const defaultValues = {
	zipCode: ''
};

const VerifyZip = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data: cancelFormResponse } = useSelector(
		(state) => state.cancelMembershipSlice.cancelMembership
	);

	const {
		data: verifyRecentlyMovedData,
		hasCompleted: verifyRecentlyMovedCompleted,
		error: verifyRecentlyMovedError
	} = useSelector((state) => state.cancelMembershipSlice.verifyRecentlyMoved);

	React.useEffect(() => {
		if (!cancelFormResponse?.programStopResponse?.zipCode) {
			navigate('/');
		}
	}, []);

	React.useEffect(() => {
		if (verifyRecentlyMovedCompleted && !verifyRecentlyMovedError) {
			if (verifyRecentlyMovedData.status === 'success') {
				navigate('/confirmation');
			} else if (verifyRecentlyMovedData.status === 'dataNotFound') {
				navigate('/no-match');
			}
		}
	}, [
		verifyRecentlyMovedData,
		verifyRecentlyMovedCompleted,
		verifyRecentlyMovedError
	]);

	const { handleSubmit, control, trigger, setError } = useForm({
		defaultValues
	});

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const onNotMoved = () => {
		const values = {
			firstName: cancelFormResponse?.programStopResponse?.firstName,
			lastName: cancelFormResponse?.programStopResponse?.lastName,
			previousZipCode: cancelFormResponse?.programStopResponse?.zipCode,
			ipAddress: '',
			subscriberNumber: cancelFormResponse?.programStopResponse?.customerNumber,
			phoneNumber: cancelFormResponse?.programStopResponse?.phoneNumber
		};
		dispatch(queueDataNotFound(values));
	};

	const onSubmit = (inputData, e) => {
		if (!inputData.zipCode) {
			setError('zipCode', {
				type: 'manual',
				message: 'Zip Code must be entered'
			});
			return;
		}
		const values = {
			firstName: cancelFormResponse?.programStopResponse?.firstName,
			lastName: cancelFormResponse?.programStopResponse?.lastName,
			previousZipCode: inputData?.zipCode,
			ipAddress: '',
			subscriberNumber: cancelFormResponse?.programStopResponse?.customerNumber,
			phoneNumber: cancelFormResponse?.programStopResponse?.phoneNumber
		};
		dispatch(verifyRecentlyMoved(values));
	};
	return (
		<>
			<Typography>
				We have a different zip code on file than the one you&apos;ve provided.
			</Typography>
			<Typography>
				Have you moved recently? If so, please provide your previous zip code
				here.
			</Typography>
			<Box width={'25%'}>
				<TextInput
					rules={{
						required: { value: true, message: t('common:fieldRequired') },
						maxLength: 9
					}}
					name='zipCode'
					type='number'
					control={control}
					label={`${t('home:formElements.zipCode')}*`}
					onChangeValidate={() => validateField('zipCode')}
				/>
			</Box>
			<StyledButton
				variant='contained'
				color='success'
				size='large'
				onClick={handleSubmit(onSubmit)}
			>
				Submit
			</StyledButton>
			<Typography fontSize='0.8em' mt={2}>
				If {cancelFormResponse?.programStopResponse?.zipCode} has always been
				your zip code, <Link onClick={onNotMoved}>click here</Link>.
			</Typography>
		</>
	);
};

VerifyZip.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(VerifyZip);
