import React from 'react';

import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RefundRequestConfirmation = () => {
	const navigate = useNavigate();
	const { result } = useSelector(
		(state) => state.cancelMembershipSlice.requestRefund
	);
	React.useEffect(() => {
		if (!result) {
			navigate('/');
		}
	}, [result]);
	return (
		<React.Fragment>
			<Typography>
				Your request has been received and is being processed.
			</Typography>
			<Typography>
				You will receive your Refund Request Form in 7-10 days.
			</Typography>
		</React.Fragment>
	);
};

export default RefundRequestConfirmation;
