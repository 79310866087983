import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	cancelMembership: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	verifyRecentlyMoved: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	queueDataNotFound: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	requestRefund: {
		result: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const cancelMembershipSlice = createSlice({
	name: 'cancelMembership',
	initialState,
	reducers: {
		cancelMembership: (state) => {
			state.cancelMembership.isLoading = true;
			state.cancelMembership.error = undefined;
		},
		cancelMembershipCompleted: (state, action) => {
			state.cancelMembership.isLoading = false;
			state.cancelMembership.hasCompleted = true;
			state.cancelMembership.data = action.payload;
		},
		cancelMembershipFailed: (state, action) => {
			state.cancelMembership.isLoading = false;
			state.cancelMembership.error = action.payload;
		},
		cancelMembershipReset: (state) => {
			state.cancelMembership = initialState.cancelMembership;
		},
		verifyRecentlyMoved: (state) => {
			state.verifyRecentlyMoved.isLoading = true;
			state.verifyRecentlyMoved.error = undefined;
		},
		verifyRecentlyMovedCompleted: (state, action) => {
			state.verifyRecentlyMoved.isLoading = false;
			state.verifyRecentlyMoved.hasCompleted = true;
			state.verifyRecentlyMoved.data = action.payload;
		},
		verifyRecentlyMovedFailed: (state, action) => {
			state.verifyRecentlyMoved.isLoading = false;
			state.verifyRecentlyMoved.error = action.payload;
		},
		verifyRecentlyMovedReset: (state) => {
			state.verifyRecentlyMoved = initialState.verifyRecentlyMoved;
		},
		queueDataNotFound: (state) => {
			state.queueDataNotFound.isLoading = true;
			state.queueDataNotFound.error = undefined;
		},
		queueDataNotFoundCompleted: (state, action) => {
			state.queueDataNotFound.isLoading = false;
			state.queueDataNotFound.hasCompleted = true;
			state.queueDataNotFound.data = action.payload;
		},
		queueDataNotFoundFailed: (state, action) => {
			state.queueDataNotFound.isLoading = false;
			state.queueDataNotFound.error = action.payload;
		},
		queueDataNotFoundReset: (state) => {
			state.queueDataNotFound = initialState.verifyRecentlyMoved;
		},
		requestRefund: (state) => {
			state.requestRefund.isLoading = true;
			state.requestRefund.error = undefined;
		},
		requestRefundCompleted: (state, action) => {
			state.requestRefund.isLoading = false;
			state.requestRefund.hasCompleted = true;
			state.requestRefund.result = action.payload;
		},
		requestRefundFailed: (state, action) => {
			state.requestRefund.isLoading = false;
			state.requestRefund.error = action.payload;
		},
		requestRefundReset: (state) => {
			state.requestRefund = initialState.requestRefund;
		}
	}
});

export const {
	cancelMembership,
	cancelMembershipCompleted,
	cancelMembershipFailed,
	cancelMembershipReset,
	verifyRecentlyMoved,
	verifyRecentlyMovedCompleted,
	verifyRecentlyMovedFailed,
	verifyRecentlyMovedReset,
	queueDataNotFound,
	queueDataNotFoundCompleted,
	queueDataNotFoundFailed,
	queueDataNotFoundReset,
	requestRefund,
	requestRefundCompleted,
	requestRefundFailed,
	requestRefundReset
} = cancelMembershipSlice.actions;

export default cancelMembershipSlice.reducer;
