import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import TextInput from '../../components/TextInput';
import helperImage from '../../assets/sub-num-help.jpeg';

import {
	cancelMembership,
	cancelMembershipReset,
	queueDataNotFoundReset,
	verifyRecentlyMovedReset
} from '../../containers/CancelMembership/cancelMembershipSlice';
import { captchaValid } from '../../containers/CaptchaValid/captchaValidSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledFormBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const LinkButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	padding: 0,
	margin: 0,
	color: 'inherit',
	textDecoration: 'underline'
}));

const defaultValues = {
	firstName: '',
	lastName: '',
	zipCode: '',
	subscriberNumber: '',
	phoneNumber: ''
};

const Home = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const captchaRef = useRef();

	const [openSubNumberDialog, setOpenSubNumberDialog] = React.useState(false);

	const { handleSubmit, control, trigger, setError, reset } = useForm({
		defaultValues
	});

	const { data, error, hasCompleted } = useSelector(
		(state) => state.cancelMembershipSlice.cancelMembership
	);
	React.useEffect(() => {
		reset();
		dispatch(cancelMembershipReset());
		dispatch(verifyRecentlyMovedReset());
		dispatch(queueDataNotFoundReset());
	}, []);

	React.useEffect(() => {
		if (hasCompleted && !error) {
			if (data?.status === 'recentlyMoved') {
				navigate('/verify-zip');
			} else if (data?.status === 'success') {
				navigate('/confirmation');
			} else {
				navigate('/no-match');
			}
		}
	}, [data, error, hasCompleted]);

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const validateCaptcha = () => {
		const captchaValue = captchaRef.current.getValue();
		if (captchaValue) {
			dispatch(captchaValid(captchaValue));
		}
	};

	const onSubmit = (inputData, e) => {
		e.preventDefault();
		validateCaptcha();
		if (!inputData.subscriberNumber && !inputData.phoneNumber) {
			setError('phoneNumber', {
				type: 'manual',
				message: t('home:formElements.mustFillOneOfTheFields')
			});
			setError('subscriberNumber', {
				type: 'manual',
				message: t('home:formElements.mustFillOneOfTheFields')
			});
			return;
		}

		dispatch(
			cancelMembership({
				firstName: inputData.firstName,
				lastName: inputData.lastName,
				zipCode: inputData.zipCode,
				subscriberNumber: parseInt(inputData.subscriberNumber),
				phoneNumber: inputData.phoneNumber
			})
		);
	};
	return (
		<React.Fragment>
			<Typography
				component='h2'
				sx={{ fontSize: '2em', fontWeight: 'bold', marginBottom: '10px' }}
			>
				{t('home:welcomeHeader')}
			</Typography>
			<Typography component='p' sx={{ margin: '15px 0' }}>
				{t('home:welcomeText')}
			</Typography>
			<StyledFormBox component='section'>
				<Stack
					direction='row'
					flexWrap='wrap'
					justifyContent='space-between'
					spacing={2}
				>
					<Box sx={{ width: '45%' }}>
						{error && (
							<Box
								sx={{
									padding: 1,
									marginBottom: 1,
									color: (theme) => theme.palette.common.white,
									backgroundColor: (theme) => theme.palette.error.main
								}}
							>
								<Typography>
									We&apos;re having trouble locating your account!
								</Typography>
								<Typography>
									Please email us at{' '}
									<Link
										to='mailto:membership@sempris.com'
										style={{ color: '#fff', fontWeight: 'bold' }}
									>
										membership@sempris.com
									</Link>
									, call us at 1-800-475-1942 so we can help you.
								</Typography>
							</Box>
						)}
						<Typography component='p'>
							* = {t('home:formElements.requiredField')}
						</Typography>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='firstName'
							control={control}
							label={`${t('home:formElements.firstName')}*`}
							onChangeValidate={() => validateField('firstName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='lastName'
							control={control}
							label={`${t('home:formElements.lastName')}*`}
							onChangeValidate={() => validateField('lastName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') },
								maxLength: 9
							}}
							name='zipCode'
							type='number'
							control={control}
							label={`${t('home:formElements.zipCode')}*`}
							onChangeValidate={() => validateField('zipCode')}
						/>
					</Box>
					<Box sx={{ width: '45%' }}>
						<Typography component='p'>{t('home:oneOfFields')}</Typography>

						<TextInput
							name='subscriberNumber'
							control={control}
							helperText={
								<LinkButton
									variant='text'
									onClick={() => setOpenSubNumberDialog(true)}
									sx={{ fontSize: '12px' }}
								>
									{t('home:formElements.subMemberNumber-subText')}
								</LinkButton>
							}
							label={t('home:formElements.subMemberNumber')}
							onChangeValidate={() => validateField('subscriberNumber')}
						/>

						<Typography component='p'>Or</Typography>

						<TextInput
							rules={{
								maxLength: 10
							}}
							name='phoneNumber'
							type='number'
							control={control}
							label={t('home:formElements.phoneNumber')}
							helperText={t('home:formElements.phoneNumber-subText')}
							onChangeValidate={() => validateField('phoneNumber')}
						/>
					</Box>
					<Box
						width='100%'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						<Box mb='15px'>
							<ReCAPTCHA
								sitekey='6Lf3JtcpAAAAADBDBl9Z3JZgDql6VsJbg0Ko8RJT'
								ref={captchaRef}
							/>
						</Box>

						<StyledButton
							variant='contained'
							color='success'
							size='large'
							disabled={error}
							onClick={handleSubmit(onSubmit)}
						>
							{t('common:buttons.submitCancelRequest')}
						</StyledButton>
					</Box>
				</Stack>
			</StyledFormBox>
			<Dialog
				open={openSubNumberDialog}
				onClose={() => setOpenSubNumberDialog(false)}
				aria-labelledby='subscriber-number-help-title'
				aria-describedby='subscriber-number-help-description'
			>
				<DialogContent>
					<img src={helperImage} alt={t('home:subscriberHelperAltText')} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSubNumberDialog(false)}>Close</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

Home.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(Home);
