import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	emailConfirmation: {
		result: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const emailConfirmationSlice = createSlice({
	name: 'emailConfirmation',
	initialState,
	reducers: {
		emailConfirmation: (state) => {
			state.emailConfirmation.isLoading = true;
			state.emailConfirmation.error = undefined;
		},
		emailConfirmationCompleted: (state, action) => {
			state.emailConfirmation.isLoading = false;
			state.emailConfirmation.hasCompleted = true;
			state.emailConfirmation.result = action.payload;
		},
		emailConfirmationFailed: (state, action) => {
			state.emailConfirmation.isLoading = false;
			state.emailConfirmation.error = action.payload;
		},
		emailConfirmationReset: (state) => {
			state.emailConfirmation = initialState.emailConfirmation;
		}
	}
});

export const {
	emailConfirmation,
	emailConfirmationCompleted,
	emailConfirmationFailed,
	emailConfirmationReset
} = emailConfirmationSlice.actions;

export default emailConfirmationSlice.reducer;
